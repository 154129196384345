<template>
  <v-app>
    <loading :active.sync="is_call_api"></loading>
    <div style="background-color: rgb(243 246 249)">
      <h1 class="mt-5 mb-5" style="color: #3f4254">
        {{
          test_set_name == null || test_set_name === ""
            ? "Test set test"
            : test_set_name
        }}
        <span v-if="model === 'vsr'">(Virtual Room)</span>
        <span v-if="model === 'test-practice'">(Test Practice)</span>
      </h1>
    </div>

    <div class="pt-5 pl-5 pr-5">
      <v-btn
        rounded
        color="primary"
        class="mb-5"
        x-small
        large
        @click="openDiaLogAddTest"
      >
        <v-icon color="white" dark>mdi-plus</v-icon
        ><span style="color: white">Thêm bài kiểm tra</span>
      </v-btn>
      <v-btn
        rounded
        color="cyan"
        class="mb-5 ml-5"
        x-small
        large
        @click="openDialogSortTestSetTest"
      >
        <v-icon color="white" dark>mdi-sort</v-icon
        ><span style="color: white">Sắp xếp bài kiểm tra</span>
      </v-btn>

      <div>
        <v-row>
          <v-col cols="12">
            <div class="table-responsive">
              <table
                class="table datatable-bordered table-head-custom table-vertical-center table-head-bg"
              >
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">Name</th>
                    <th scope="col">Duration</th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>
                <tbody>
                  <template v-for="(test, i) in test_set_tests">
                    <tr :key="i">
                      <td scope="row">#{{ test.id }}</td>
                      <td class="">
                        <p
                          class="text-dark-75 font-weight-bolder d-block font-size-lg"
                        >
                          {{ test.title }}
                        </p>
                      </td>
                      <td class="">
                        <p
                          class="text-dark-75 font-weight-bolder d-block font-size-lg"
                        >
                          {{ test.duration }}
                        </p>
                      </td>
                      <td>
                        <v-tooltip right>
                          <template v-slot:activator="{ on, attrs }">
                            <button
                              class="btn btn-icon btn-light-danger btn-sm ml-2"
                              v-bind="attrs"
                              v-on="on"
                              @click="btnDeleteTestSetTest(test.id)"
                            >
                              <span
                                class="svg-icon svg-icon-md svg-icon-primary"
                              >
                                <v-icon color="">mdi-delete</v-icon>
                              </span>
                            </button>
                          </template>
                          <span>Delete</span>
                        </v-tooltip>
                        <v-tooltip right>
                          <template v-slot:activator="{ on, attrs }">
                            <router-link
                              :to="{
                                name: 'EditTest',
                                params: { id: test.id },
                              }"
                              target="_blank"
                            >
                              <button
                                class="btn btn-icon btn-light-warning btn-sm ml-4"
                                v-bind="attrs"
                                v-on="on"
                              >
                                <span
                                  class="svg-icon svg-icon-md svg-icon-primary"
                                >
                                  <v-icon color=""
                                    >mdi-chevron-triple-right</v-icon
                                  >
                                </span>
                              </button>
                            </router-link>
                          </template>
                          <span>Edit Test</span>
                        </v-tooltip>
                      </td>
                    </tr>
                  </template>
                </tbody>
              </table>
            </div>
          </v-col>
        </v-row>
      </div>
    </div>

    <v-row justify="center">
      <v-dialog v-model="dialogAddTest" scrollable max-width="1000px">
        <v-card>
          <v-card-title>
            <v-col cols="11">
              <span class="headline">Thêm bài kiểm tra</span>
            </v-col>
            <v-col cols="1">
              <v-icon @click="dialogAddTest = false" right>mdi-close</v-icon>
            </v-col>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <label style="font-size: 16px">Chọn bài kiểm tra</label>
                  <div>
                    <div style="float: left; width: 250px">
                      <b-form-input
                        class="mt-0"
                        style="width: 100%"
                        placeholder="Search"
                        v-model="search_test_input"
                        @keyup.enter="enterSearchTest()"
                      ></b-form-input>
                      <span
                        style="clear: both; display: inline-block"
                        class="text-subtitle-1 grey--text darken-1"
                        >(Enter để tìm kiếm)</span
                      >
                    </div>
                    <v-pagination
                      style="float: right"
                      color="#00BCD4"
                      v-model="data_pagination_test.current_page"
                      :length="data_pagination_test.total_pages"
                      :total-visible="6"
                      @input="getAllTest"
                    ></v-pagination>
                    <div class="table-responsive" style="overflow: hidden">
                      <table
                        class="table table-bordered table-head-custom table-vertical-center table-head-bg"
                      >
                        <thead class="thead-ipp">
                          <tr>
                            <th scope="col">#</th>
                            <th scope="col">Title</th>
                          </tr>
                        </thead>
                        <tbody>
                          <template v-for="(test, i) in tests">
                            <tr :key="test.id + i">
                              <td>
                                <v-radio-group
                                  v-model="test_input"
                                  class="mt-0 mb-0 pt-0"
                                  style="max-height: 25px"
                                >
                                  <v-radio label="" :value="test.id"></v-radio>
                                </v-radio-group>
                              </td>
                              <td>
                                <span
                                  class="text-dark-75 font-weight-normal d-block font-size-lg"
                                  >#{{ test.id }} - {{ test.title }}</span
                                >
                              </td>
                            </tr>
                          </template>
                          <template v-if="tests.length <= 0">
                            <tr>
                              <td style="text-align: center" colspan="3">
                                No data
                              </td>
                            </tr>
                          </template>
                        </tbody>
                      </table>
                    </div>
                    <v-pagination
                      style="float: right"
                      color="#00BCD4"
                      v-model="data_pagination_test.current_page"
                      :length="data_pagination_test.total_pages"
                      :total-visible="6"
                    ></v-pagination>
                  </div>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn outlined color="blue darken-1" @click="btnAddTest()"
              >Thêm</v-btn
            >
            <v-btn color="red darken-1" text @click="dialogAddTest = false"
              >Thoát</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>

    <v-row justify="center">
      <v-dialog
        v-model="dialogSortTestSetTest"
        scrollable
        persistent
        max-width="600px"
      >
        <v-card>
          <v-card-title>
            <v-col cols="11">
              <span class="headline">Sort Lesson/ Test</span>
            </v-col>
            <v-col cols="1">
              <v-icon @click="dialogSortTestSetTest = false" right
                >mdi-close</v-icon
              >
            </v-col>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <draggable :list="data_draggable">
                    <ul
                      class="list-unstyled drag-css cursor-pointer"
                      v-for="(test, i) in data_draggable"
                      :key="i"
                    >
                      <b-media tag="li">
                        <h5 class="mt-0 mb-1" style="color: #3699ff">
                          {{ test.title }}
                        </h5>
                      </b-media>
                    </ul>
                  </draggable>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" outlined @click="sortTestSetTest"
              >Update</v-btn
            >
            <v-btn
              color="red darken-1"
              text
              @click="dialogSortTestSetTest = false"
              >Close</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </v-app>
</template>

<script>
import ApiService from "@/service/api.service";
import "vue-loading-overlay/dist/vue-loading.css";
import Swal from "sweetalert2";
import draggable from "vuedraggable";

export default {
  name: "TestSetTest",
  components: {
    Loading: () => import("vue-loading-overlay"),
    draggable,
  },
  data() {
    return {
      is_call_api: false,
      test_set_tests: [],
      test_set_name: "",
      tests: [],
      data_pagination_test: {
        current_page: 0,
        total_pages: 1,
      },
      test_input: null,
      dialogAddTest: false,
      search_test_input: "",
      dialogSortTestSetTest: false,
      data_draggable: [],
    };
  },
  computed: {
    test_set_id: function () {
      return this.$route.params.id;
    },
    model: function () {
      return this.$route.params.model;
    },
  },
  created() {
    this.getTestSetTest();
    this.getAllTest();
  },
  methods: {
    async getTestSetTest() {
      let vm = this;
      await ApiService.get(
        "prep-app/test-set/" + this.test_set_id + "/test-set-test"
      ).then(function (response) {
        if (response.status === 200) {
          vm.test_set_tests = response.data.tests;
          vm.test_set_name = response.data.test_set_name;
        }
      })
      .catch(function (error) {
        vm.is_call_api = false;
        if (error.response.status === 422) {
          if (error.response.data.error.message) {
            vm.errorMessage(error.response.data.error.message);
          }
        }
      });
    },
    errorMessage(msg = 'error') {
      this.$toasted.error(msg, {theme: "toasted-primary", position: "top-right", duration: 4000,});
    },

    async getAllTest(page = 1) {
      let vm = this;
      vm.is_call_api = true;
      await ApiService.get(
        "prep-app/test?page=" +
          page +
          "&name=" +
          this.checkQuerySearchTest(this.search_test_input)
      ).then(function (response) {
        if (response.status === 200) {
          vm.is_call_api = false;
          vm.tests = response.data.data;
          vm.data_pagination_test = response.data.meta.pagination;
        }
      });
    },

    openDiaLogAddTest() {
      this.test_input = null;
      this.dialogAddTest = true;
    },
    validateForm() {
      let flat = true;
      if (this.test_input === null) {
        this.$toasted.error("Hãy chọn bài kiểm tra!!", {
          theme: "toasted-primary",
          position: "top-right",
          duration: 4000,
        });
        flat = false;
      }
      return flat;
    },
    btnAddTest() {
      let validateForm = this.validateForm();
      if (!validateForm) {
        return;
      }
      let vm = this;
      let data = {
        test_id: this.test_input,
        offset: this.test_set_tests.length + 1,
      };
      this.is_call_api = true;
      ApiService.post(
        "prep-app/test-set/" + this.test_set_id + "/test-set-test",
        data
      )
        .then(function (res) {
          if (res.status === 200) {
            vm.$toasted.success("Thêm thành công !!", {
              theme: "toasted-primary",
              position: "top-right",
              duration: 4000,
            });
            vm.getTestSetTest();
            vm.is_call_api = false;
            vm.dialogAddTest = false;
          }
        })
        .catch(function (error) {
          vm.is_call_api = false;
          if (error.response.status === 422) {
            let data_response_error =
              error.response.data.error.message.split(".<br />");
            vm.showError(data_response_error);
          }
        });
    },
    checkQuerySearchTest(query) {
      if (query.substring(0, 1) === "#") {
        return "id-" + query.substring(1);
      }
      return query;
    },
    enterSearchTest() {
      let vm = this;
      vm.is_call_api = true;
      ApiService.get(
        "prep-app/test?page=" +
          1 +
          "&name=" +
          this.checkQuerySearchTest(this.search_test_input)
      ).then(function (response) {
        if (response.status === 200) {
          vm.tests = response.data.data;
          vm.data_pagination_test = response.data.meta.pagination;
          vm.is_call_api = false;
        }
      });
    },
    btnDeleteTestSetTest(test_id) {
      let vm = this;
      Swal.fire({
        title: "Are you sure you want to delete?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.value) {
          ApiService.delete(
            "prep-app/test-set/" +
              this.test_set_id +
              "/test-set-test/" +
              test_id
          )
            .then(function (res) {
              if (res.status === 200) {
                vm.$toasted.success("Xóa thành công !!", {
                  theme: "toasted-primary",
                  position: "top-right",
                  duration: 4000,
                });
                vm.getTestSetTest();
              }
            })
            .catch(function (error) {
              console.log(error);
            });
        }
      });
    },
    openDialogSortTestSetTest() {
      this.data_draggable = JSON.parse(JSON.stringify(this.test_set_tests));
      this.dialogSortTestSetTest = true;
    },
    sortTestSetTest() {
      let vm = this;
      Swal.fire({
        title: "Are you sure you want save?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes!",
      }).then((result) => {
        if (result.value) {
          let data_sort = this.data_draggable.map((item, index) => {
            return {
              test_id: item.id,
              offset: index + 1,
            };
          });
          let data = {
            data_sort: data_sort,
          };
          vm.is_call_api = true;
          ApiService.post(
            "prep-app/test-set/" + this.test_set_id + "/test-set-test/sort",
            data
          )
            .then(function (res) {
              if (res.status === 200) {
                vm.getTestSetTest();
                vm.$toasted.success("Săp xếp thành công !!", {
                  theme: "toasted-primary",
                  position: "top-right",
                  duration: 4000,
                });
                vm.is_call_api = false;
                vm.dialogSortTestSetTest = false;
              }
            })
            .catch(function (error) {
              vm.is_call_api = false;
              console.log(error);
            });
        }
      });
    },
  },
};
</script>

<style scoped>
.table.table-head-bg thead th {
  background-color: #2196f3eb !important;
  color: white !important;
}
.table td {
  vertical-align: middle;
}
</style>
